import React from "react"
import {Link, graphql} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo";
import Social from '../components/social';

export default function FederalPage({data, pageContext}) {
  const single = data.single.nodes;
  const headOfHouseHold = data.headOfHouseHold.nodes;
  const marriedJointly = data.marriedJointly.nodes;
  const taxYear = pageContext.Year;
  const currentDate = new Date().getFullYear();

  return (
    <Layout>
      <SEO title={"Federal Tax Brackets for Tax Year " + taxYear} 
      description={"The Federal tax rates in " + taxYear + " for those that are single or married filing taxes separately are " + single[0]["Rate"] + 
      " up to " + single[0]["Single___End"] + " earned. For those married filing taxes jointly the tax rates are ..." }/>
      <div>

        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <nav aria-label="breadcrumb">
                <div className="breadcrumb">
                  <div className="breadcrumb-item"><Link to="/">Home</Link></div>
                  <div className="breadcrumb-item active" aria-current="page">{taxYear}</div>
                </div>
              </nav>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-sm-12 col-md-8">
              <p className="hero-meta">Last updated: {currentDate}</p>
              <h1 className="hero-heading">Federal Tax Bracket information for {taxYear}</h1>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-sm-12 col-md-8">
              <Social />
              <hr/>
              <h2>Federal Income Tax</h2>
              <p>Income tax is a tax that is imposed on people and businesses based on the income or profits that they earned. The tax rates are broken down into groups called <em>tax brackets</em>.
                Income tax brackets are required taxes in the US based on relative income and earnings.</p>

              <p><a href="/federal-tax-forms">IRS Federal Tax Forms</a> are also available.</p>


              <div className="container">
                <div className="row">
                  <div className="col-sm">
                    <h4>Filing Single Tax Brackets</h4>
                    <table className="table tax__bracket">
                      <tr>
                        <th>Tax Rate</th>
                        <th>Tax Bracket</th>
                      </tr>
                      <tbody>
                      {single.map(bracket => {
                        const {
                          Rate,
                          Single___Start,
                          Single___End,
                        } = bracket;

                        return (
                          <tr key={bracket.uniqueId}>
                            <td>{Rate}</td>
                            <td>{Single___Start} - {Single___End}</td>
                          </tr>
                        )
                      })}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-sm">
                    <h4>Married Filing Separately Tax Brackets</h4>
                    <table className="table tax__bracket">
                      <tr>
                        <th>Tax Rate</th>
                        <th>Tax Bracket</th>
                      </tr>
                      <tbody>
                      {single.map(bracket => {
                        const {
                          Rate,
                          Single___Start,
                          Single___End,
                        } = bracket;

                        return (
                          <tr key={bracket.uniqueId}>
                            <td>{Rate}</td>
                            <td>{Single___Start} - {Single___End}</td>
                          </tr>
                        )
                      })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-sm">
                    <h4>Married Filing Jointly Tax Brackets</h4>
                    <table className="table tax__bracket">
                      <tr>
                        <th>Tax Rate</th>
                        <th>Tax Bracket</th>
                      </tr>
                      <tbody>
                      {marriedJointly.map(bracket => {
                        const {
                          Rate,
                          Married_Individuals_Filing_Joint_Returns___Start,
                          Married_Individuals_Filing_Joint_Returns___End
                        } = bracket;

                        return (
                          <tr key={bracket.uniqueId}>
                            <td>{Rate}</td>
                            <td>{Married_Individuals_Filing_Joint_Returns___Start} - {Married_Individuals_Filing_Joint_Returns___End}</td>
                          </tr>
                        )
                      })}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-sm">
                  <h4>Filing Head of Household Tax Brackets</h4>
                    <table className="table tax__bracket">
                      <tr>
                        <th>Tax Rate</th>
                        <th>Tax Bracket</th>
                      </tr>
                      <tbody>
                      {headOfHouseHold.map(bracket => {
                        const {
                          Rate,
                          Heads_of_Households___Start,
                          Heads_of_Households___End
                        } = bracket;

                        return (
                          <tr key={bracket.uniqueId}>
                            <td>{Rate}</td>
                            <td>{Heads_of_Households___Start} - {Heads_of_Households___End}</td>
                          </tr>
                        )
                      })}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="row pt-3">
                  <div className="col-sm">
                    <h3>Types of Federal Tax Brackets</h3>
                    <p>
                      There are four types of tax brackets that map to four different filing types, each with different bracket widths.
                      These bracket types allow taxpayers filing as Married Filing Jointly (MFS) or Head of Household to pay less in taxes by
                      widening each tax bracket's width.
                    </p>

                    <ul>
                      <li><strong>Single</strong> - The Single brackets are applicable to all single non-joint filers,  have the narrowest bracket width, and generally result in the highest individual income tax liability.</li>

                      <li><strong>Married Filing Jointly</strong> - The Married Filing Jointly tax brackets are applicable to all legally married couples filing their income tax on a joint return.  This is the most advantageous filing type for the following reasons:
                        <ol>
                          <li>The width of the first three tax brackets are doubled, and the highest four brackets are also expanded for joint filers.</li>
                          <li>The IRS provides join filers one of the largest standard deductions each year, allowing them to deduct a significant amount of their income</li>
                          <li>Usually qualifies the couples for multiple tax credits such as:
                            <ul>
                              <li>Earned Income Tax Credit</li>
                              <li>Child and Dependent Care Tax Credit</li>
                              <li>Exclusion or credit for adoption expenses</li>
                              <li>American Opportunity and Lifetime Learning education tax credits</li>
                            </ul>
                          </li>
                        </ol>
                      </li>

                      <li><strong>Head of Household</strong> - Head of Household is a special filing status reserved for single individuals who support one or more dependants by themselves. Head of Household tax brackets are wider than Single brackets, but not as wide as joint brackets.</li>

                      <li><strong>Married Filing Separately</strong> - Married Filing Separately is a special filing type for individuals who are married,
                        but choose to file separate income tax returns.  Typically, using this filing status usually has many disadvantages such as:
                        <ul>
                          <li>Lower standard deduction</li>
                          <li>Disqualifies from many tax credits</li>
                          <li>Smaller amount of IRA contribution deduction</li>
                          <li>Disqualifies from taking the student loan interest deduction</li>
                          <li>Capital losses deduction are cut by 50% to only $1500 instead of $3000</li>
                        </ul>

                        Married couples should only consider this filing status in the rare cases where there is a large amount of out-of-pocket medical expenses to claim
                        since the IRS limits the deduction amount of these costs that exceeds 7.5% of your adjusted gross income (AGI) which is difficult to go over if filing jointly.  Otherwise, a vast majority of couples would end up
                        paying more taxes than if they were to file jointly.  The best way to find out is to run the numbers for both filing types to see the differnces.</li>
                    </ul>


                    <h3>Federal Standard Deduction</h3>
                    <p>
                      <p>The standard deduction is a dollar amount that reduces your total taxable income.
                        In 2020 the standard deduction is $12,400 for single filers and married filing separately,
                        $18,650 for head of household, and $24,800 for married filing jointly.</p>

                      <p>In 2021 the standard deduction is $12,550 for single filers and married filing separately,
                        $18,800 for head of household, and $25,100 for joint filers.</p>

                      <p>See our expanded <Link to={'/federal-standard-deduction'}>Federal Standard Deduction</Link> info.</p>
                    </p>
                  </div>
                </div>




              </div>

            </div>
            {/* End main column */}
          </div>
          {/* End row */}
        </div>
        {/* End container */}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($Year: Date) {
    single: allFederalincometaxCsv(filter: {Year: {eq: $Year}}) {
      nodes {
        Rate
        Single___Start
        Single___End
      }
    }
    marriedJointly: allFederalincometaxCsv(filter: {Year: {eq: $Year}}) {
      nodes {
        Rate
        Married_Individuals_Filing_Joint_Returns___Start
        Married_Individuals_Filing_Joint_Returns___End
      }
    }
    headOfHouseHold: allFederalincometaxCsv(filter: {Year: {eq: $Year}}) {
      nodes {
        Rate
        Heads_of_Households___Start
        Heads_of_Households___End
      }
    }
  }
`
